import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import Iframe from "react-iframe";

const Dashboard = (props) => {
  const [userId, setUserId] = React.useState("");

  React.useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user"))._id;
    setUserId(id);
  }, []);

  return (
    <Card>
      <Title title="Welcome to the administration" />
      <CardContent>
        {userId && (
          <Iframe
            url={`https://lookerstudio.google.com/embed/reporting/d81c5f8f-fd5d-4084-bd2b-b5c0e9157d62/page/UYFIC?params=%7B"df18":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${userId}"%7D`}
            width="1200px"
            height="1000px"
            id=""
            className=""
            styles={{ border: "0" }}
            display="block"
            allowFullScreen={true}
            position="relative"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Dashboard;
