const dark = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
    unit: "px",
  },
  direction: "ltr",
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
      styleOverrides: {
        action: {
          marginTop: "-4px",
          marginRight: "-4px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          boxShadow:
            "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
          backgroundImage: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          fontWeight: "300",
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          height: "64px",
        },
      },
    },
    MuiPickersCalendar: {
      styleOverrides: {
        transitionContainer: {
          marginTop: "6px",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        iconButton: {
          backgroundColor: "transparent",
          "& > *": {
            backgroundColor: "transparent",
          },
        },
        switchHeader: {
          marginTop: "2px",
          marginBottom: "4px",
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        container: {
          margin: "32px 0 4px",
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        clockNumber: {
          left: "calc(50% - 16px)",
          width: "32px",
          height: "32px",
        },
      },
    },
    MuiPickerDTHeader: {
      styleOverrides: {
        dateHeader: {
          "& h4": {
            fontSize: "2.125rem",
            fontWeight: 400,
          },
        },
        timeHeader: {
          "& h3": {
            fontSize: "3rem",
            fontWeight: 400,
          },
        },
      },
    },
    MuiPickersTimePicker: {
      styleOverrides: {
        hourMinuteLabel: {
          "& h2": {
            fontSize: "3.75rem",
            fontWeight: 300,
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          "& h4": {
            fontSize: "2.125rem",
            fontWeight: 400,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#407ad6",
      contrastText: "#FFF",
      light: "rgb(102, 148, 222)",
      dark: "rgb(44, 85, 149)",
    },
    secondary: {
      main: "#4782da",
      contrastText: "#FFF",
      light: "rgb(107, 155, 225)",
      dark: "rgb(49, 91, 152)",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: "rgba(255, 255, 255, 0.12)",
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h5: {
      fontSize: "1.0625rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    body1: {
      fontSize: 13,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      textTransform: "none",
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: 1.75,
    },
    htmlFontSize: 16,
    subtitle1: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.9285714285714286rem",
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: 1.57,
    },
    body2: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.8125rem",
      lineHeight: 1.43,
    },
    caption: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.6964285714285714rem",
      lineHeight: 1.66,
    },
    overline: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.6964285714285714rem",
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },
  shadows: [
    "none",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
  ],
  unstable_sxConfig: {
    border: {
      themeKey: "borders",
    },
    borderTop: {
      themeKey: "borders",
    },
    borderRight: {
      themeKey: "borders",
    },
    borderBottom: {
      themeKey: "borders",
    },
    borderLeft: {
      themeKey: "borders",
    },
    borderColor: {
      themeKey: "palette",
    },
    borderTopColor: {
      themeKey: "palette",
    },
    borderRightColor: {
      themeKey: "palette",
    },
    borderBottomColor: {
      themeKey: "palette",
    },
    borderLeftColor: {
      themeKey: "palette",
    },
    borderRadius: {
      themeKey: "shape.borderRadius",
    },
    color: {
      themeKey: "palette",
    },
    bgcolor: {
      themeKey: "palette",
      cssProperty: "backgroundColor",
    },
    backgroundColor: {
      themeKey: "palette",
    },
    p: {},
    pt: {},
    pr: {},
    pb: {},
    pl: {},
    px: {},
    py: {},
    padding: {},
    paddingTop: {},
    paddingRight: {},
    paddingBottom: {},
    paddingLeft: {},
    paddingX: {},
    paddingY: {},
    paddingInline: {},
    paddingInlineStart: {},
    paddingInlineEnd: {},
    paddingBlock: {},
    paddingBlockStart: {},
    paddingBlockEnd: {},
    m: {},
    mt: {},
    mr: {},
    mb: {},
    ml: {},
    mx: {},
    my: {},
    margin: {},
    marginTop: {},
    marginRight: {},
    marginBottom: {},
    marginLeft: {},
    marginX: {},
    marginY: {},
    marginInline: {},
    marginInlineStart: {},
    marginInlineEnd: {},
    marginBlock: {},
    marginBlockStart: {},
    marginBlockEnd: {},
    displayPrint: {
      cssProperty: false,
    },
    display: {},
    overflow: {},
    textOverflow: {},
    visibility: {},
    whiteSpace: {},
    flexBasis: {},
    flexDirection: {},
    flexWrap: {},
    justifyContent: {},
    alignItems: {},
    alignContent: {},
    order: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    alignSelf: {},
    justifyItems: {},
    justifySelf: {},
    gap: {},
    rowGap: {},
    columnGap: {},
    gridColumn: {},
    gridRow: {},
    gridAutoFlow: {},
    gridAutoColumns: {},
    gridAutoRows: {},
    gridTemplateColumns: {},
    gridTemplateRows: {},
    gridTemplateAreas: {},
    gridArea: {},
    position: {},
    zIndex: {
      themeKey: "zIndex",
    },
    top: {},
    right: {},
    bottom: {},
    left: {},
    boxShadow: {
      themeKey: "shadows",
    },
    width: {},
    maxWidth: {},
    minWidth: {},
    height: {},
    maxHeight: {},
    minHeight: {},
    boxSizing: {},
    fontFamily: {
      themeKey: "typography",
    },
    fontSize: {
      themeKey: "typography",
    },
    fontStyle: {
      themeKey: "typography",
    },
    fontWeight: {
      themeKey: "typography",
    },
    letterSpacing: {},
    textTransform: {},
    lineHeight: {},
    textAlign: {},
    typography: {
      cssProperty: false,
      themeKey: "typography",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px)": {
        "@media (orientation: landscape)": {
          minHeight: 48,
        },
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  name: "DARK",
  header: {
    color: "#e0e0e0",
    background: "#1B2635",
    search: {
      color: "#eeeeee",
    },
    indicator: {
      background: "#407ad6",
    },
  },
  footer: {
    color: "#e0e0e0",
    background: "#233044",
  },
  sidebar: {
    color: "#eeeeee",
    background: "#233044",
    header: {
      color: "#eeeeee",
      background: "#233044",
      brand: {
        color: "#4782da",
      },
    },
    footer: {
      color: "#eeeeee",
      background: "#1E2A38",
      online: {
        background: "#4caf50",
      },
    },
    badge: {
      color: "#FFF",
      background: "#4782da",
    },
  },
};
const light = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
    unit: "px",
  },
  direction: "ltr",
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
      styleOverrides: {
        action: {
          marginTop: "-4px",
          marginRight: "-4px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          boxShadow:
            "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
          backgroundImage: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          fontWeight: "300",
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          height: "64px",
        },
      },
    },
    MuiPickersCalendar: {
      styleOverrides: {
        transitionContainer: {
          marginTop: "6px",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        iconButton: {
          backgroundColor: "transparent",
          "& > *": {
            backgroundColor: "transparent",
          },
        },
        switchHeader: {
          marginTop: "2px",
          marginBottom: "4px",
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        container: {
          margin: "32px 0 4px",
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        clockNumber: {
          left: "calc(50% - 16px)",
          width: "32px",
          height: "32px",
        },
      },
    },
    MuiPickerDTHeader: {
      styleOverrides: {
        dateHeader: {
          "& h4": {
            fontSize: "2.125rem",
            fontWeight: 400,
          },
        },
        timeHeader: {
          "& h3": {
            fontSize: "3rem",
            fontWeight: 400,
          },
        },
      },
    },
    MuiPickersTimePicker: {
      styleOverrides: {
        hourMinuteLabel: {
          "& h2": {
            fontSize: "3.75rem",
            fontWeight: 300,
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          "& h4": {
            fontSize: "2.125rem",
            fontWeight: 400,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#376fd0",
      contrastText: "#FFF",
      light: "rgb(95, 139, 217)",
      dark: "rgb(38, 77, 145)",
    },
    secondary: {
      main: "#4782da",
      contrastText: "#FFF",
      light: "rgb(107, 155, 225)",
      dark: "rgb(49, 91, 152)",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h5: {
      fontSize: "1.0625rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    body1: {
      fontSize: 13,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      textTransform: "none",
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: 1.75,
    },
    htmlFontSize: 16,
    subtitle1: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.9285714285714286rem",
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: 1.57,
    },
    body2: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.8125rem",
      lineHeight: 1.43,
    },
    caption: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.6964285714285714rem",
      lineHeight: 1.66,
    },
    overline: {
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      fontSize: "0.6964285714285714rem",
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },
  shadows: [
    "none",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
    "box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);",
  ],
  unstable_sxConfig: {
    border: {
      themeKey: "borders",
    },
    borderTop: {
      themeKey: "borders",
    },
    borderRight: {
      themeKey: "borders",
    },
    borderBottom: {
      themeKey: "borders",
    },
    borderLeft: {
      themeKey: "borders",
    },
    borderColor: {
      themeKey: "palette",
    },
    borderTopColor: {
      themeKey: "palette",
    },
    borderRightColor: {
      themeKey: "palette",
    },
    borderBottomColor: {
      themeKey: "palette",
    },
    borderLeftColor: {
      themeKey: "palette",
    },
    borderRadius: {
      themeKey: "shape.borderRadius",
    },
    color: {
      themeKey: "palette",
    },
    bgcolor: {
      themeKey: "palette",
      cssProperty: "backgroundColor",
    },
    backgroundColor: {
      themeKey: "palette",
    },
    p: {},
    pt: {},
    pr: {},
    pb: {},
    pl: {},
    px: {},
    py: {},
    padding: {},
    paddingTop: {},
    paddingRight: {},
    paddingBottom: {},
    paddingLeft: {},
    paddingX: {},
    paddingY: {},
    paddingInline: {},
    paddingInlineStart: {},
    paddingInlineEnd: {},
    paddingBlock: {},
    paddingBlockStart: {},
    paddingBlockEnd: {},
    m: {},
    mt: {},
    mr: {},
    mb: {},
    ml: {},
    mx: {},
    my: {},
    margin: {},
    marginTop: {},
    marginRight: {},
    marginBottom: {},
    marginLeft: {},
    marginX: {},
    marginY: {},
    marginInline: {},
    marginInlineStart: {},
    marginInlineEnd: {},
    marginBlock: {},
    marginBlockStart: {},
    marginBlockEnd: {},
    displayPrint: {
      cssProperty: false,
    },
    display: {},
    overflow: {},
    textOverflow: {},
    visibility: {},
    whiteSpace: {},
    flexBasis: {},
    flexDirection: {},
    flexWrap: {},
    justifyContent: {},
    alignItems: {},
    alignContent: {},
    order: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    alignSelf: {},
    justifyItems: {},
    justifySelf: {},
    gap: {},
    rowGap: {},
    columnGap: {},
    gridColumn: {},
    gridRow: {},
    gridAutoFlow: {},
    gridAutoColumns: {},
    gridAutoRows: {},
    gridTemplateColumns: {},
    gridTemplateRows: {},
    gridTemplateAreas: {},
    gridArea: {},
    position: {},
    zIndex: {
      themeKey: "zIndex",
    },
    top: {},
    right: {},
    bottom: {},
    left: {},
    boxShadow: {
      themeKey: "shadows",
    },
    width: {},
    maxWidth: {},
    minWidth: {},
    height: {},
    maxHeight: {},
    minHeight: {},
    boxSizing: {},
    fontFamily: {
      themeKey: "typography",
    },
    fontSize: {
      themeKey: "typography",
    },
    fontStyle: {
      themeKey: "typography",
    },
    fontWeight: {
      themeKey: "typography",
    },
    letterSpacing: {},
    textTransform: {},
    lineHeight: {},
    textAlign: {},
    typography: {
      cssProperty: false,
      themeKey: "typography",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px)": {
        "@media (orientation: landscape)": {
          minHeight: 48,
        },
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  name: "LIGHT",
  header: {
    color: "#eeeeee",
    background: "#2f65cb",
    search: {
      color: "#f5f5f5",
    },
    indicator: {
      background: "#d32f2f",
    },
  },
  footer: {
    color: "#9e9e9e",
    background: "#FFF",
  },
  sidebar: {
    color: "#212121",
    background: "#FFF",
    header: {
      color: "#FFF",
      background: "#2f65cb",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#424242",
      background: "#F7F7F7",
      online: {
        background: "#4caf50",
      },
    },
    badge: {
      color: "#FFF",
      background: "#4782da",
    },
  },
};
const themes = {
  dark,
  light,
};

export default themes;
