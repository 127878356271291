import * as React from "react";
import { Datagrid, TextField, List, DateField } from "react-admin";
import { SmsOtpFilterSidebar } from "./SmsOtpFilterSidebar";
import { SmsFilters } from "./SmsOTPFilterTopbar";

// const ResourceName = () => {
//   const resource = useResourceContext();
//   return <>{resource}</>;
// };

export const SMSList = () => {
  return (
    <List
      perPage={25}
      aside={<SmsOtpFilterSidebar />}
      filters={SmsFilters}
      empty={false}
    >
      {/*<ResourceName /> /!* renders 'posts' *!/*/}
      <Datagrid>
        <TextField source="phone" label="SĐT" />
        <TextField source="mess" label="Mã OTP" />
        <TextField source="tranId" label="Mã định danh" />
        <DateField
          source="createdAt"
          label="Thời gian gửi"
          showTime={true}
          locales="vi-VN"
        />
      </Datagrid>
    </List>
  );
};
