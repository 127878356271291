import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { Sun, Moon } from "react-feather";
import { useState } from "react";
import { useEffect } from "react";

const MyAppBar = (props) => {
  const [theme, _setTheme] = useState("");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") || "dark";
    _setTheme(storedTheme);
  }, []);

  const setTheme = (theme) => {
    console.log(theme);
    localStorage.setItem("theme", theme);
    _setTheme(theme);
    window.location.reload(false);
  };

  return (
    <AppBar
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          justifyContent: "space-between",
        },
      }}
      // userMenu={UserMenu}
      {...props}
    >
      <Typography variant="h6" color="inherit" />
      <div
        style={{
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        MILTECH
      </div>
      <div style={{ flex: 1 }}></div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      >
        {theme === "dark" ? <Sun size={20} /> : <Moon size={20} />}
      </div>
    </AppBar>
  );
};

export default memo(MyAppBar);
