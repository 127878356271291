import * as React from "react";
import { Datagrid, TextField, List, DateField } from "react-admin";
import { VoiceOtpFilterSidebar } from "./VoiceOtpFilterSidebar";
import { voiceFilters } from "./VoiceOTPFilterTopbar";

// const ResourceName = () => {
//   const resource = useResourceContext();
//   return <>{resource}</>;
// };

export const VoiceOtpList = () => {
  return (
    <List
      perPage={25}
      aside={<VoiceOtpFilterSidebar />}
      filters={voiceFilters}
      empty={false}
    >
      <>
        {/*<ResourceName /> /!* renders 'posts' *!/*/}
        <Datagrid>
          <TextField source="phone" label="SĐT" />
          <TextField source="mess" label="Mã OTP" />
          <TextField source="tranId" label="Mã định danh" />
          <TextField source="status" label="Tình trạng" />
          <DateField
            source="createdAt"
            label="Thời gian gửi"
            showTime={true}
            locales="vi-VN"
          />
        </Datagrid>
      </>
    </List>
  );
};
