import * as React from "react";

import { Admin, Resource } from "react-admin";

import { SMSList } from "./sms/smsList";
import { VoiceOtpList } from "./VoiceOTP/VoiceOtpList";
import { authProvider } from "./authProvider";
import { dataProvider } from "./dataProvider";
import { MainLayout } from "./MainLayout";
import themes from "./theme";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import { useEffect } from "react";

const App = () => {
  const themeOption = localStorage.getItem("theme") || "dark";
  const selectedTheme = themes[themeOption];
  useEffect(() => {
    setTimeout(() => {
      const mainContent = window.document.getElementById("main-content");
      if (themeOption === "dark") {
        mainContent.style.background = "#18222f";
      } else {
        mainContent.style.background = "#eff1f4";
      }
    }, 0);
  }, [themeOption]);

  return (
    <Admin
      layout={MainLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={selectedTheme}
      dashboard={Dashboard}
    >
      <Resource
        name="otps/sms/reports"
        options={{ label: "Báo cáo Voice-OTP" }}
        list={SMSList}
      />
      <Resource name="otps/call/reports" list={VoiceOtpList} />
      {/*<Resource name="apps" list={AppList} />*/}
    </Admin>
  );
};

export default App;
