import { SavedQueriesList, FilterList, FilterListItem } from "react-admin";
import { Card, CardContent } from "@mui/material";
import { Bookmark, Calendar } from "react-feather";

export const SmsOtpFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9.5, width: 200 }}>
    <CardContent>
      <SavedQueriesList icon={<Bookmark color="grey" size={20} />} />
      {/*<FilterLiveSearch />*/}
      <FilterList
        label="Thời gian sẵn"
        icon={<Calendar color="grey" size={20} />}
      >
        <FilterListItem label="Hôm nay" value={{ _quick_date: "today" }} />
        <FilterListItem label="Hôm qua" value={{ _quick_date: "yesterday" }} />
        <FilterListItem label="Tuần này" value={{ _quick_date: "wtd" }} />
        <FilterListItem label="Tháng này" value={{ _quick_date: "mtd" }} />
        <FilterListItem
          label="Tháng trước"
          value={{ _quick_date: "last_month" }}
        />
      </FilterList>
      {/* <FilterList label="Tình Trạng" icon={<Filter color="grey" size={20} />}>
        <FilterListItem label="Mới" value={{ status: "New" }} />
        <FilterListItem label="Đang gọi" value={{ status: "Calling" }} />
        <FilterListItem label="Thành công" value={{ status: "Success" }} />
        <FilterListItem
          label="Không thể gửi"
          value={{ status: "Can not sent" }}
        />
        <FilterListItem label="Gửi thất bại" value={{ status: "Sent fail" }} />
        <FilterListItem label="Thất bại" value={{ status: "Failed" }} />
      </FilterList> */}
    </CardContent>
  </Card>
);
