import { BASE_URL } from "./utils/constant";
import axios from "axios";

export const authProvider = {
  login: async ({ username, password }) => {
    try {
      const resp = await axios.post(`${BASE_URL}/auth/login`, {
        username,
        password,
      });
      console.log(resp.data);
      if (resp.status < 200 || resp.status >= 300) {
        throw new Error(resp.statusText);
      }

      const token = resp.data.response.accessToken;
      localStorage.setItem("token", token);

      const userProfile = await axios.get(`${BASE_URL}/users/profile`, {
        headers: { authorization: "Bearer " + token },
      });
      localStorage.setItem("user", JSON.stringify(userProfile.data.response));
    } catch (error) {
      throw error;
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const id = user._id;
      const fullName = user.firstname;
      const avatar = user.avatar || "";
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
