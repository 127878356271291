import { BASE_URL } from "./utils/constant";
import axios from "axios";
// in dataProvider.js
import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import moment from "moment";

const httpClient = fetchUtils.fetchJson;
const DATE_FORMAT = "YYYY-MM-DD";

export const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filterObj = params.filter;
    if (filterObj._quick_date) {
      switch (filterObj._quick_date) {
        case "today":
          // filterObj.startDate =
          break;
        case "yesterday":
          filterObj.startDate = moment().subtract(1, "day").startOf("day");
          filterObj.endDate = moment().subtract(1, "day").endOf("day");
          break;
        case "wtd":
          filterObj.startDate = moment().startOf("week");
          filterObj.endDate = moment().endOf("week");
          break;
        case "mtd":
          filterObj.startDate = moment().startOf("month");
          filterObj.endDate = moment().endOf("month");
          break;
        case "last_month":
          filterObj.startDate = moment().startOf("month").subtract(1, "months");
          filterObj.endDate = moment(filterObj.startDate).endOf("month");
          break;
        default:
          break;
      }
    }
    const query = {
      page,
      perPage,
      ...params.filter,
      startDate: moment(filterObj.startDate).format(DATE_FORMAT),
      endDate: moment(filterObj.endDate).format(DATE_FORMAT),
    };

    const userProfile = localStorage.getItem("user");
    const data = JSON.parse(userProfile);
    const url = `${BASE_URL}/${resource}/${data.app._id}?${stringify(query)}`;

    const resp = await axios.get(url, {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    });
    // console.log("resp.data.response.data", resp.data.response);
    return {
      data: resp.data.response.data.map((d) => ({ ...d, id: d._id })),
      total: resp.data.response.total,
    };
  },

  getOne: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      // sort: JSON.stringify([field, order]),
      offset: page * perPage,
      limit: page * perPage + perPage,
      // filter: JSON.stringify(params.filter),
    };
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  create: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  update: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${BASE_URL}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${BASE_URL}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
