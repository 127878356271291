// in src/MyMenu.js
import * as React from "react";
import { Menu } from "react-admin";
import { Sliders, Voicemail, MessageSquare } from "react-feather";

export const MainMenu = () => (
  <Menu>
    <Menu.Item
      to="/"
      primaryText="Bảng Tổng Hợp"
      leftIcon={<Sliders color="grey" size={20} />}
    />
    {/*<Menu.Item to="/apps" primaryText="Quản lý APP" leftIcon={<ChatBubbleIcon />}/>*/}
    <Menu.Item
      to="/otps/call/reports"
      primaryText="Báo cáo Voice-OTP"
      leftIcon={<Voicemail color="grey" size={20} />}
    />
    <Menu.Item
      to="/otps/sms/reports"
      primaryText="Báo cáo SMS-OTP"
      leftIcon={<MessageSquare color="grey" size={20} />}
    />
  </Menu>
);
