import { DateInput } from "react-admin";

export const SmsFilters = [
  <DateInput
    sx={{
      marginBottom: "6px",
    }}
    source="startDate"
    alwaysOn
    label="Ngày bắt đầu"
  />,
  <DateInput
    sx={{
      marginBottom: "6px",
    }}
    source="endDate"
    alwaysOn
    label="Ngày kết thúc"
  />,
];
